"use client";

import Link from "next/link";
import {
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  Ref,
  RefObject,
} from "react";
import { HorizontalSpinner } from "src/common/ui/HorizontalSpinner";

export const SIZES = ["sm", "md", "lg"] as const;
export const VARIANTS = [
  "primary",
  "secondary",
  "transparent",
  "alert",
] as const;
export const COLOR_SCHEMA = [
  "base",
  "rewards",
  "landing",
  "landing-filled",
  "share-benefit",
] as const;

type SizeTuple = typeof SIZES;
type Size = SizeTuple[number];

type VariantTuple = typeof VARIANTS;
type Variant = VariantTuple[number];

type ColorSchemaTuple = typeof COLOR_SCHEMA;
type ColorSchema = ColorSchemaTuple[number];
export interface ButtonProps extends HTMLAttributes<HTMLElement> {
  as?: "button" | "div" | "a" | "label";
  children: ReactNode;
  className?: string;
  form?: string;
  forwardRef?: RefObject<HTMLElement & HTMLDivElement>;
  htmlFor?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  rounded?: boolean;
  size: Size;
  style?: CSSProperties;
  type?: "button" | "submit" | "reset";
  variant: Variant;
  colorSchema?: ColorSchema;
}

export interface ButtonLinkProps extends HTMLAttributes<HTMLElement> {
  as: typeof Link;
  children: ReactNode;
  className?: string;
  forwardRef?: RefObject<HTMLElement & HTMLDivElement>;
  href?: string;
  target?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: (event?: any) => void;
  rounded?: boolean;
  size: Size;
  style?: CSSProperties;
  variant: Variant;
  tabindex?: string | number;
  colorSchema?: ColorSchema;
}

function ButtonInner(
  {
    as: As = "button",
    children,
    className,
    forwardRef,
    isLoading,
    onClick,
    rounded = true,
    size,
    style,
    variant,
    colorSchema,
    ...rest
  }: ButtonProps | ButtonLinkProps,
  ref: Ref<HTMLButtonElement | HTMLDivElement | HTMLLinkElement>,
) {
  const isDisabled = ("isDisabled" in rest && rest.isDisabled) ?? false;
  const isActive = !isDisabled && !isLoading;

  function getStyle() {
    if (isDisabled) {
      return "bg-disabled cursor-not-allowed text-white";
    }
    if (colorSchema && colorSchema === "rewards") {
      return `transition-all bg-rewards-600 hover:bg-rewards-800 text-white ${
        isActive ? `active:bg-rewards-900` : ""
      }`;
    }

    if (colorSchema && colorSchema === "share-benefit") {
      return `transition-all bg-blueNews-600 hover:bg-blueNews-500 text-white ${
        isActive ? `active:bg-blueNews-500` : ""
      }`;
    }

    if (colorSchema && colorSchema === "landing") {
      return `transition-all bg-transparent text-sm font-semibold text-counterGreen-600 border-1 border-counterGreen-400 !px-5 hover:bg-counterGreen-50 ${
        isActive ? `active:bg-counterGreen-50` : ""
      }`;
    }

    if (colorSchema && colorSchema === "landing-filled") {
      return `transition-all bg-counterGreen-500 text-sm font-semibold text-white !px-5 hover:bg-counterGreen-700 ${
        isActive ? `active:bg-counterGreen-700` : ""
      }`;
    }
    switch (variant) {
      case "primary":
        return `bg-button-primary-default hover:bg-button-primary-hover text-white ${
          isActive ? `active:bg-button-primary-active` : ""
        }`;
      case "secondary":
        return "bg-button-secondary-default hover:bg-button-secondary-hover text-white";
      case "transparent":
        return `bg-transparent border-1 box-border hover:border-input-focus border-default border-gray-300 ${
          isActive ? "active:border-input-focus" : ""
        }`;
      case "alert":
        return `bg-alert-800 hover:bg-alert-950 text-white ${
          isActive ? `active:bg-button-alert-950-active` : ""
        }`;
    }
  }

  function getRounded() {
    if (rounded) {
      return "rounded-full";
    } else {
      return "rounded-button";
    }
  }

  function getSize() {
    switch (size as ButtonProps["size"]) {
      case "sm":
        return `px-2 3xl:px-3 text-xs font-[450] tracking-tight h-8 3xl:h-9 3xl:text-sm ${
          isActive ? "active:scale-[0.97]" : ""
        }`;
      case "md":
        return `px-3 3xl:px-4 text-sm font-[450] tracking-tight h-9 3xl:h-11 3xl:text-sm ${
          isActive ? "active:scale-[0.98]" : ""
        }`;
      default:
      case "lg":
        return `px-6 3xl:px-5 text-base font-[600] tracking-tight h-[50px] 3xl:h-12 ${
          isActive ? "active:scale-[0.99]" : ""
        }`;
    }
  }

  if ("isDisabled" in rest) {
    if (rest.isDisabled) As = "button";
    delete rest.isDisabled;
  }

  return (
    <As
      onClick={onClick}
      className={`box-border inline-flex items-center justify-center whitespace-nowrap outline-none focus-visible:outline-1.5 focus-visible:outline-offset-3 focus-visible:outline-black ${getRounded()} ${getStyle()} ${getSize()} ${
        className ?? ""
      }`}
      disabled={!isActive}
      // @ts-expect-error: Type '(instance: HTMLDivElement | HTMLButtonElement | HTMLLinkElement | null) => void' is not assignable to type 'string'.
      ref={ref}
      {...rest}
    >
      <span className="grid">
        <span
          className={`col-[1/2] row-[1/2] flex items-center ${
            isLoading ? "opacity-0" : ""
          }`}
        >
          {children}
        </span>

        {isLoading && (
          <span className="col-[1/2] row-[1/2] grid place-items-center">
            <div className="grid aspect-square w-[2ch] place-items-center xl:w-[2.5ch]">
              <HorizontalSpinner />
            </div>
          </span>
        )}
      </span>
    </As>
  );
}

export const Button = forwardRef(ButtonInner);
